<template>
  <div class="container">
      <img class="background" :src="require(`@/assets/${backgroundImage}.webp`)" :alt="alt">
      <div class="meme-container">
        <img
          v-for="(image, index) in images"
          :key="index"
          class="meme"
          :class="{'active-image': currentImage === index}" 
          :src="require(`@/assets/${image.filename}.webp`)"
          :alt="image.alt"
        >
      </div>
      
  </div>
</template>

<script>
export default {
  name: 'ImageCustom',

  props: {
    backgroundImage: String,
    alt: String,
    images: Array
  },

   data() {
    return {
      currentImage: 0,
      intervalId: null,
    }
  },

  mounted() {
    this.intervalId = setInterval(this.SwitchImage, 3000);
  },

  beforeUnmount() {
    clearInterval(this.intervalId);
  },

  methods: {
    SwitchImage() {
      this.currentImage++;

      if (this.currentImage >= this.images.length)
      {
        this.currentImage = 0;
      }
    }
  },
}
</script>


<style scoped>

.container, img {
  width: 100%;
}

.container, .background {
  position: relative;
}

.background {
  width: 100%;
}

.meme-container {
  position: absolute;
  top: 10%;
  left: 60%;
  width: 26.2666%;
  height: 29.5679%;
  max-width: 479px;
  max-height: 394px;
}

.meme {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.active-image {
  opacity: 1;
  z-index: 5;
}


</style>
