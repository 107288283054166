<template>
  <div class="swiper" @touchstart="TouchStart">
    <img class="background" :src="require(`@/assets/${backgroundImage}.webp`)" :alt="backgroundAlt">

    <img
      v-for="(item, index) in images"
      :key="index"
      class="clean"
      :class="{ 'visible': item.isActive }"
      v-show="type === 1"
      :src="item.cleanImage"
      :alt="item.alt"
      >

    <img
      v-for="(item, index) in images"
      :key="index"
      class="priced"
      :class="{ 'visible': item.isActive }"
      v-show="type === 2"
      :src="item.pricedImage"
      :alt="item.alt"
      >

     <div class="arrow-container arrow-left-container" @click="PrevImage">
      <img class="arrow-left arrow-purple" :src="require(`@/assets/arrow_purple.webp`)" alt="clickable purple arrow pointing to the left">
      <img class="arrow-left arrow-pink" :src="require(`@/assets/arrow_pink.webp`)" alt="clickable pink arrow pointing to the left">
    </div>

    <div class="arrow-container arrow-right-container" @click="NextImage">
      <img class="arrow-right arrow-purple" :src="require(`@/assets/arrow_purple.webp`)" alt="clickable purple arrow pointing to the left">
      <img class="arrow-right arrow-pink" :src="require(`@/assets/arrow_pink.webp`)" alt="clickable pink arrow pointing to the left">
    </div>

    <div class="swiper-buttons">
      <button class="swiper-button button-text" @click="TogglePrices">PRICES</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SheetExampleSwiper',

  props: {
    backgroundImage: String,
    backgroundAlt: String,
    images: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeGroup: 0,
      type: 1,
    }
  },

  mounted() {

    this.images.forEach(element => {
      element.cleanImage = require(`@/assets/${element.filename + '_clean'}.webp`);
      element.pricedImage = require(`@/assets/${element.filename + '_priced'}.webp`);
    });

  },

   methods: {

    TogglePrices() {
      this.type = this.type == 1 ? 2 : 1
    },

    NextImage() {
      this.images[this.activeGroup].isActive = false

      this.activeGroup++;

      if (this.activeGroup >= this.images.length)
      {
        this.activeGroup = 0;
      }

      this.images[this.activeGroup].isActive = true
    },

     PrevImage() {

      this.images[this.activeGroup].isActive = false

      this.activeGroup--;

      if (this.activeGroup < 0)
      {
        this.activeGroup = (this.images.length - 1);
      }

      this.images[this.activeGroup].isActive = true
    },

    TouchStart (touchEvent) {
        console.log("boop")
        // We only care if one finger is used


        if (touchEvent.changedTouches.length !== 1)
        { 
          return;
        }

        const posXStart = touchEvent.changedTouches[0].clientX;
        addEventListener('touchend', (touchEvent) => this.TouchEnd(touchEvent, posXStart), {once: true});
      },

    TouchEnd (touchEvent, posXStart) {

        // We only care if one finger is used
        if (touchEvent.changedTouches.length !== 1)
        { 
          return;
        }

        const posXEnd = touchEvent.changedTouches[0].clientX;

        if (posXStart < posXEnd)
        {
          // Swipe right:
          this.PrevImage()
        }
        else if (posXStart > posXEnd)
        {
          // Swipe left:
          this.NextImage()
        }
      }




  },
}
</script>


<style scoped>

.swiper {
  position: relative;
  z-index: 10;
}

img {
  width: 100%;
}

.background {
  position: relative;
}

.priced, .clean {
  position: absolute;
  left: 0;
  top: 20%;
  z-index: 0;
  opacity: 0;
  transition: 0.4s ease-in-out;
}

.visible {
  opacity: 1;
}

.swiper-buttons {
  position: absolute;
  bottom: 15%;
  left: 20%;
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.swiper-button {
  width: 30%;
  height: 5.8954%;
  border: 3px solid #ff84a5;
  border-radius: 10px;
  background-color: transparent;
  color: #ff84a5;
  transition: 0.2s ease-in-out;
}

.swiper-button:hover {
  border: 3px solid #a576d7;
  color: #a576d7;
}

.swiper-button:focus {
  border: 3px solid #a576d7;
  color: #ff84a5;
}


.arrow-container {
  width: 5%;
  position: absolute;
  top: 50%;
}

.arrow-left-container {
  left: 1%;
}

.arrow-right-container {
  right: 1%;
}

.arrow-left {
  position: absolute;
  transform: scaleX(-1);
}

.arrow-right {
  position: absolute;
  transform: scaleX(1);
}

.arrow-pink {
  opacity: 0;
  position: absolute;
}

.arrow-pink:hover {
  opacity: 1;
}

@media  (width <= 760px) {
  .swiper-button {
    border: 1px solid #ff84a5;
  }
}



</style>
