<template>
  <div class="container">
    <img class="background" :src="require(`@/assets/${backgroundImage}.webp`)" :alt="alt">
    <div class="overlay">
        <div class="r1"></div>
        <div class="r2">
          <div class="c1"></div>
          <div class="c2">
            <div class="p1">
              <StickerPackage :images="packageData1"/>
              <StickerPackage :images="packageData2"/>
              <StickerPackage :images="packageData3"/>
            </div>
            <div class="p2">
              <StickerPackage :images="packageData4"/>
              <StickerPackage :images="packageData5"/>
              <StickerPackage :images="packageData6"/>
            </div>
            
          </div>
          <div class="c3"></div>
        </div>
        <div class="r3"></div>
    </div>
  </div>
</template>

<script>
import StickerPackage from '@/components/StickerPackage.vue'

export default {
  name: 'PackageZone',

   components: {
      StickerPackage
    },

  props: {
    backgroundImage: String,
    alt: String,
    images: Array
  },

   data() {
    return {
      currentImage: 0,
      intervalId: null,

      packageData1: {
        alt: 'sticker example of Sett the cat',
        species: 'Cat',
        name: 'SETH',
        telegramLink: 'https://t.me/addstickers/SW_CatSeth',
        filenames: [
          'stickers_p1_s1',
          'stickers_p1_s2',
          'stickers_p1_s3',
          'stickers_p1_s4',
          'stickers_p1_s5',
        ],
      },

      packageData2: {
        alt: 'sticker example of Klank the kobold',
        species: 'Kobold',
        name: 'KLANK',
        telegramLink: 'https://t.me/addstickers/SW_KoboldKlank',
        filenames: [
          'stickers_p2_s1',
          'stickers_p2_s2',
          'stickers_p2_s3',
          'stickers_p2_s4',
          'stickers_p2_s5',
        ],
      },

      packageData3: {
        alt: 'sticker example of Sasha the bee bunny',
        species: 'BeeBunny',
        name: 'SASHA',
        telegramLink: 'https://t.me/addstickers/SW_ImBeeTheBunny',
        filenames: [
          'stickers_p3_s1',
          'stickers_p3_s2',
          'stickers_p3_s3',
          'stickers_p3_s4',
          'stickers_p3_s5',
        ],
      },

      packageData4: {
        alt: 'sticker example of Tinel the kobold',
        species: 'Kobold',
        name: 'TINEL',
        telegramLink: 'https://t.me/addstickers/SW_DndTinel',
        filenames: [
          'stickers_p4_s1',
          'stickers_p4_s2',
          'stickers_p4_s3',
          'stickers_p4_s4',
          'stickers_p4_s5',
        ],
      },

      packageData5: {
        alt: 'sticker example of Tina the mouse',
        species: 'Mouse',
        name: 'TINA',
        telegramLink: 'https://t.me/addstickers/SW_TinaMouse',
        filenames: [
          'stickers_p5_s1',
          'stickers_p5_s2',
          'stickers_p5_s3',
          'stickers_p5_s4',
          'stickers_p5_s5',
        ],
      },

      packageData6: {
        alt: 'sticker example of Kertinox the dragon',
        species: 'Dragon',
        name: 'KERTINOX',
        telegramLink: 'https://t.me/addstickers/SW_Kertinox',
        filenames: [
          'stickers_p6_s1',
          'stickers_p6_s2',
          'stickers_p6_s3',
          'stickers_p6_s4',
          'stickers_p6_s5',
        ],
      },


    }
  },

  methods: {
   
  },
}
</script>


<style scoped>

.container, .background {
  width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.r1, .r2, .r3 {
    width: 100%;
}

.r1 {
  height: 15.5%;
}

.r2 {
  height: 78%;
  display: flex;
  justify-content: column;
}

.r3 {
  height: 6.5%;
}

.c1, .c2, .c3 {
  height: 100%;
}

.c1 {
  width: 10%;
}

.c2 {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p1, .p2 {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.c3 {
  width: 10%;
}


</style>
