<template>
  <div class="cs-main">
    <div class="g1">
      <ImageWide
        :image="'comic1'"
        :alt="'image of card with sketch art example'"
      />
    </div>

    <div class="g2">
      <ImageWide
        :image="'comic2'"
        :alt="'image of card with sketch art example'"
      />
    </div>

    <div class="g3">
      <ImageWide
        :image="'comic3'"
        :alt="'image of card with sketch art example'"
      />
    </div>

  </div>
</template>

<script>
import ImageWide from '@/components/ImageWide.vue'

export default {
  name: 'ContentStyles',

   components: {
        ImageWide
    },

  data() {
    return {

    }
  },

  props: {

  },

  methods: {

  },
}
</script>


<style scoped>

.cs-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.g1, .g2, .g3 {
  margin-top: 10%;
}

</style>
